import { useEffect } from "react";

const Resume = () => {
  useEffect(() => {
    window.location.href =
      "https://ritzy-phalange-090.notion.site/48592773e6cf4d3396d7ce80fa3bf84a";
  }, []);

  return null;
};

export default Resume;
